<template>
  <div id="givenchyangenoir">
      <div class="container bg-white">
          <div class="row text-center">
              <div class="col-12 p-4 mt-5">
                  <img class="img-fluid" src="../assets/images/givenchy/ange_noir/logo-givenchy.jpg" alt="">
              </div>
              <div class="col-12 p-4">
                  <img class="img-fluid" src="../assets/images/givenchy/ange_noir/strategie-angenoir.jpg" alt="">
              </div>
              <div class="col-12 p-4">
                  <img class="img-fluid" src="../assets/images/givenchy/ange_noir/logo-angenoir.jpg" alt="">
              </div>
          </div>
          <div class="row">
              <div class="col-12 mb-30 text-center">
                  <h2>FACEBOOK APP</h2>
              </div>
              <div class="col-12">
                  <splide :options="options" class="p-5">
                      <splide-slide>
                          <div>
                              <img class="img-fluid" src="../assets/images/givenchy/ange_noir/slide-angenoir1.jpg" alt="L'Ange Noir">
                          </div>
                      </splide-slide>
                      <splide-slide>
                          <div>
                              <img class="img-fluid" src="../assets/images/givenchy/ange_noir/slide-angenoir2.jpg" alt="L'Ange Noir">
                          </div>
                      </splide-slide>
                      <splide-slide>
                          <div>
                              <img class="img-fluid" src="../assets/images/givenchy/ange_noir/slide-angenoir3.jpg" alt="L'Ange Noir">
                          </div>
                      </splide-slide>
                      <splide-slide>
                          <div>
                              <img class="img-fluid" src="../assets/images/givenchy/ange_noir/slide-angenoir4.jpg" alt="L'Ange Noir">
                          </div>
                      </splide-slide>
                      <splide-slide>
                          <div>
                              <img class="img-fluid" src="../assets/images/givenchy/ange_noir/slide-angenoir5.jpg" alt="L'Ange Noir">
                          </div>
                      </splide-slide>
                      <splide-slide>
                          <div>
                              <img class="img-fluid" src="../assets/images/givenchy/ange_noir/slide-angenoir6.jpg" alt="L'Ange Noir">
                          </div>
                      </splide-slide>
                      <splide-slide>
                          <div>
                              <img class="img-fluid" src="../assets/images/givenchy/ange_noir/slide-angenoir7.jpg" alt="L'Ange Noir">
                          </div>
                      </splide-slide>
                  </splide>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    export default {
        components: {
            Splide,
            SplideSlide,
        },
        data() {
            return {
                artiste: '',
                options: {
                    type  : 'fade',
                    rewind: true,
                    pagination: false,
                    arrows: false,
                    autoplay: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    speed: 1000,
                },
            }
        },
    }
</script>

<style lang="scss" scoped>
    #givenchyangenoir{
        padding-top: 100px;
        color: black;
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    h2{
        color: #9b7b96;
    }
</style>

<style lang="scss">
    #givenchyangenoir{
        // @splidejs/splide/dist/css/themes/splide-default.min.css
        // avec modif
        @keyframes splide-loading {
            0% {
                transform: rotate(0)
            }
            to {
                transform: rotate(1turn)
            }
        }
        .splide__container {
            position: relative;
            box-sizing: border-box
        }
        .splide__list {
            margin: 0 !important;
            padding: 0 !important;
            width: max-content;
            will-change: transform
        }
        .splide.is-active .splide__list {
            display: flex
        }
        .splide__pagination {
            display: inline-flex;
            align-items: center;
            width: 95%;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0
        }
        .splide__pagination li {
            list-style-type: none;
            display: inline-block;
            line-height: 1;
            margin: 0
        }
        .splide {
            visibility: hidden
        }
        .splide, .splide__slide {
            position: relative;
            outline: none
        }
        .splide__slide {
            box-sizing: border-box;
            list-style-type: none !important;
            margin: 0;
            flex-shrink: 0
        }
        .splide__slide img {
            vertical-align: bottom
        }
        .splide__slider {
            position: relative
        }
        .splide__spinner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #999;
            border-left-color: transparent;
            animation: splide-loading 1s linear infinite
        }
        .splide__track {
            position: relative;
            z-index: 0;
            overflow: hidden
        }
        .splide--draggable > .splide__track > .splide__list > .splide__slide {
            user-select: none
        }
        .splide--fade > .splide__track > .splide__list {
            display: block
        }
        .splide--fade > .splide__track > .splide__list > .splide__slide {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            opacity: 0
        }
        .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
            position: relative;
            z-index: 1;
            opacity: 1
        }
        .splide--rtl {
            direction: rtl
        }
        .splide--ttb > .splide__track > .splide__list {
            display: block
        }
        .splide--ttb > .splide__pagination {
            width: auto
        }
        .splide__arrow {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            width: 2em;
            height: 2em;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            padding: 0;
            /*opacity: .7;*/
            background: #B32572;
        }
        .splide__arrow svg {
            width: 1.2em;
            height: 1.2em;
            fill: white;
        }
        .splide__arrow:hover {
            cursor: pointer;
            opacity: .9
        }
        .splide__arrow:focus {
            outline: none
        }
        .splide__arrow--prev {
            left: 1em
        }
        .splide__arrow--prev svg {
            transform: scaleX(-1)
        }
        .splide__arrow--next {
            right: 1em
        }
        .splide__pagination {
            position: absolute;
            z-index: 1;
            bottom: .5em;
            left: 50%;
            transform: translateX(-50%);
            padding: 0
        }
        .splide__pagination__page {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: white;
            border-radius: 50%;
            margin: 3px;
            padding: 0;
            transition: transform .2s linear;
            border: none;
            /*opacity: .7*/
        }
        .splide__pagination__page.is-active {
            transform: scale(1.4);
            background: #B32572;
        }
        .splide__pagination__page:hover {
            cursor: pointer;
            opacity: .9
        }
        .splide__pagination__page:focus {
            outline: none
        }
        .splide__progress__bar {
            width: 0;
            height: 3px;
            background: #B32572;
        }
        .splide--nav > .splide__track > .splide__list > .splide__slide {
            border: 3px solid transparent
        }
        .splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
            border-color: #000
        }
        .splide--nav > .splide__track > .splide__list > .splide__slide:focus {
            outline: none
        }
        .splide--rtl > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
            right: 1em;
            left: auto
        }
        .splide--rtl > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
            transform: scaleX(1)
        }
        .splide--rtl > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
            left: 1em;
            right: auto
        }
        .splide--rtl > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
            transform: scaleX(-1)
        }
        .splide--ttb > .splide__arrows .splide__arrow, .splide--ttb > .splide__track > .splide__arrows .splide__arrow {
            left: 50%;
            transform: translate(-50%)
        }
        .splide--ttb > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
            top: 1em
        }
        .splide--ttb > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
            transform: rotate(-90deg)
        }
        .splide--ttb > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
            top: auto;
            bottom: 1em
        }
        .splide--ttb > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
            transform: rotate(90deg)
        }
        .splide--ttb > .splide__pagination {
            display: flex;
            flex-direction: column;
            bottom: 50%;
            left: auto;
            right: .5em;
            transform: translateY(50%)
        }

        // modif splide fullscreen
        .splide {
            .splide__track{
                height: 100%;

                .splide__list{
                    height: 100%;
                }

                .splide__slide{
                    height: 100%;
                }
            }
        }
    }
</style>
